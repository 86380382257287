var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"editProduct"},[(_vm.productPrepare)?_c('b-form',{staticClass:"mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleSubmit.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('h4',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t('Detalles del Producto'))+" ")])]),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Nombre'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Nombre'),"label-for":"product-name"}},[_c('b-form-input',{attrs:{"name":"name","readonly":_vm.role === 'admin_empresa',"placeholder":_vm.$t('Nombre')},model:{value:(_vm.product.name),callback:function ($$v) {_vm.$set(_vm.product, "name", $$v)},expression:"product.name"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4018053706)})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('codigoArticulo')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('codigoArticulo'),"label-for":"product-codigoArticulo"}},[_c('b-form-input',{attrs:{"name":"codart","readonly":_vm.role === 'admin_empresa',"placeholder":_vm.$t('codigoArticulo')},model:{value:(_vm.product.codart),callback:function ($$v) {_vm.$set(_vm.product, "codart", $$v)},expression:"product.codart"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,201783295)})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('ean13')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('ean13'),"label-for":"product-ean13"}},[_c('b-form-input',{attrs:{"name":"ean13","readonly":_vm.role === 'admin_empresa',"placeholder":_vm.$t('ean13')},model:{value:(_vm.product.ean13),callback:function ($$v) {_vm.$set(_vm.product, "ean13", $$v)},expression:"product.ean13"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3732701829)})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Modelo')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Modelo'),"label-for":"product-modelo"}},[_c('b-form-input',{attrs:{"name":"modelo","placeholder":_vm.$t('Modelo'),"readonly":_vm.role === 'admin_empresa'},model:{value:(_vm.product.modelo),callback:function ($$v) {_vm.$set(_vm.product, "modelo", $$v)},expression:"product.modelo"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3454471757)})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Fabricante')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Fabricante'),"label-for":"product-fabricante"}},[_c('b-form-input',{attrs:{"name":"fabricante","readonly":_vm.role === 'admin_empresa',"placeholder":_vm.$t('Fabricante')},model:{value:(_vm.product.fabricante),callback:function ($$v) {_vm.$set(_vm.product, "fabricante", $$v)},expression:"product.fabricante"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4100239534)})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Categoría'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Categoría'),"label-for":"product-category"}},[_c('SelectCategories',{model:{value:(_vm.category),callback:function ($$v) {_vm.category=$$v},expression:"category"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,184559000)})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('TieneCaducidad')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('TieneCaducidad'),"label-for":"product-caducidad"}},[_c('b-form-checkbox',{staticClass:"mt-1",attrs:{"id":"caducidad","name":"check-button-caducidad","switch":"","inline":""},model:{value:(_vm.caducidad),callback:function ($$v) {_vm.caducidad=$$v},expression:"caducidad"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,570817143)})],1),(_vm.caducidad)?_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('CaducidadAños')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('CaducidadAños'),"label-for":"product-caducidadAnos"}},[_c('b-form-input',{attrs:{"name":"caducidadAnos","type":"number","readonly":_vm.role === 'admin_empresa',"placeholder":_vm.$t('CaducidadAños')},model:{value:(_vm.product.caducidadAnos),callback:function ($$v) {_vm.$set(_vm.product, "caducidadAnos", $$v)},expression:"product.caducidadAnos"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3248454891)})],1):_vm._e(),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Revisión obligatoria')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Revisión obligatoria'),"label-for":"product-revision-obligatoria"}},[_c('b-form-checkbox',{staticClass:"mt-1",attrs:{"id":"revision-obligatoria","name":"check-button-revision-obligatoria","switch":"","inline":""},model:{value:(_vm.revisionObligatoria),callback:function ($$v) {_vm.revisionObligatoria=$$v},expression:"revisionObligatoria"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2802458801)})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Revisión periódica')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Revisión periódica'),"label-for":"product-revision-periodica"}},[_c('b-form-checkbox',{staticClass:"mt-1",attrs:{"id":"revision-periodica","disabled":_vm.revisionPeriodicaDisabled,"name":"check-button-revision-periodica","switch":"","inline":""},model:{value:(_vm.revisionPeriodica),callback:function ($$v) {_vm.revisionPeriodica=$$v},expression:"revisionPeriodica"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1660028167)})],1),(_vm.revisionPeriodica)?_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Frecuencia revisiones'),"rules":_vm.revisionPeriodicaRequired},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Frecuencia revisiones'),"label-for":"product-frequency"}},[_c('v-select',{attrs:{"filterable":true,"searchable":true,"options":_vm.selectRevisiones,"placeholder":_vm.$t('Frecuencia revisiones')},model:{value:(_vm.frecuencia),callback:function ($$v) {_vm.frecuencia=$$v},expression:"frecuencia"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,119153326)})],1):_vm._e(),_c('b-col',{attrs:{"sm":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Descripcion')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Descripcion'),"label-for":"product-description"}},[_c('quill-editor',{model:{value:(_vm.product.description),callback:function ($$v) {_vm.$set(_vm.product, "description", $$v)},expression:"product.description"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2573279650)})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Imagen'),"label-for":"product-image"}},[(_vm.productPrepare)?_c('ImageDropzone',{ref:"image",attrs:{"files":_vm.imagen,"max-files":"1"}}):_vm._e()],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('certificadoConformidad'),"label-for":"product-certificadoConformidad"}},[(_vm.productPrepare)?_c('ImageDropzone',{ref:"certificadoConformidad",attrs:{"files":_vm.certificadoConformidad,"max-files":"1"}}):_vm._e()],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('fichaTecnica'),"label-for":"product-fichaTecnica"}},[(_vm.productPrepare)?_c('ImageDropzone',{ref:"fichaTecnica",attrs:{"files":_vm.fichaTecnica,"max-files":"1"}}):_vm._e()],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('manualUso'),"label-for":"product-manualUso"}},[(_vm.productPrepare)?_c('ImageDropzone',{ref:"manualUso",attrs:{"files":_vm.manualUso,"max-files":"1"}}):_vm._e()],1)],1),_c('b-col',{attrs:{"cols":"12 text-right"}},[_c('b-button',{staticClass:"mt-2 mr-1",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('Enviar'))+" ")])],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }